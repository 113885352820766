import React from 'react'
import ChatRoom from './components/ChatRoom'

const App = () => {
  return (
    <ChatRoom />
  )
}

export default App;

